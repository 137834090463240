import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PercentageCircle from "components/PercentageCircle/PercentageCircle";
import { ModuleStateType } from "utils/constants";

const ProgressIcon = ({ state, ruleType, hasContent, className, hasProgress, progress, handleClick, handleOpenLockModal, handleOpenCourseTraversalModal, showPercentage = false }) => {
	const getProgressString = () => {
		if (progress === 100) {
			return hasProgress ? "Completed" : "Watched";
		}
		if (progress === 0) {
			return "Not started";
		}
		return showPercentage ? progress + "%" : "In progress";
	};

	const renderSubmoduleProgress = () => {
		if (state === ModuleStateType.Enabled) {
			if (hasContent) {
				return (
					<div className={className} onClick={(e) => handleClick()}>
						<PercentageCircle progress={progress} />
						<p className="small-text">{getProgressString()}</p>
					</div>
				);
			}
			else {
				return (
					<div className={`${className} locked`}>
						<div className="locked-icon">
							<FontAwesomeIcon icon={faClock} />
						</div>
						<p className="small-text">Coming soon</p>
					</div>
				);
			}
		}
		if (state === ModuleStateType.DisabledUpsell) {
			return (
				<div className={`${className} locked`} onClick={handleOpenLockModal}>
					<div className="locked-icon">
						<FontAwesomeIcon icon={faLock} />
					</div>
					<p className="small-text">Locked</p>
				</div>
			);
		}
		if (state === ModuleStateType.CourseTraversal) {
			return (
				<div className={`${className} soft-locked`} onClick={() => handleOpenCourseTraversalModal(ruleType)}>
					<div className="locked-icon">
						<FontAwesomeIcon icon={faLock} />
					</div>
					<p className="small-text">Locked</p>
				</div>
			);
		}
		return null;
	};
	
	return (<>{renderSubmoduleProgress()}</>);
};

export default ProgressIcon;
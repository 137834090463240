/**
	* @constant
	* @type {number}
	* @default
	*/
export const MIN_PASSWORD_LENGTH = 8;

/**
	* @constant
	* @type {number}
	* @default
	*/
export const MAX_PASSWORD_LENGTH = 64;

/**
	* @constant
	* @type {string}
	* @default
	*/
export const SEPARATOR = '\f';

/**
	* @constant
	* @type {string}
	* @default
	*/
export const SEPARATOR_REGEX = /\f/g;

/**
	* @readonly
	* @enum {number}
	*/
export const ModuleCustomType = Object.freeze({
	DeviceTraining: 1,
	AdminTraining: 2,
	SoftBundledGift: 3,
	ClickableUpsell: 4,
	NonClickable: 5
});

/**
	* @readonly
	* @enum {number}
	*/
export const ModuleStateType = Object.freeze({
	Enabled: 0,
	EnabledUpsellPrereq: 1,
	EnabledUpsell: 2,
	DisabledNotPaid: 3,
	DisabledNotEnrolled: 4,
	DisabledUpsellPrereq: 5,
	DisabledUpsell: 6,
	CourseTraversal: 7,
	Hidden: 8
});

/**
	* @readonly
	* @enum {number}
	*/
export const TraversalRuleType = Object.freeze({
	None: 0,
	EnableAfterFirst: 1,
	EnableAfterPrevious: 2
});

/**
	* @constant
	* @type {number}
	* @default
	*/
export const MOBILE_WIDTH = 768;
import './UserEditor.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import useUserData from "hooks/useUserData";
import InfoIcon from 'components/InfoIcon/InfoIcon';

const UserForm = ({
	users,
	edit,
	prerequisites,
	formData,
	setFormData,
	contentGroupOptions,
	deviceGroupOptions,
	deviceGroupUserCount,
	customDeviceGroupOptions,
	packagesWithGurusByUser,
	handleDeviceIconClick,
	handleDeleteIconClick
}) => {
	const { userData } = useUserData();
	const maxContentGroupLines = 5;

	const contentGroupsWithLockedContent = contentGroupOptions.filter(x => x.HasTraversalRule);
	const contentGroupsClass = contentGroupOptions.length + contentGroupsWithLockedContent.length > maxContentGroupLines ? 'overflowed' : '';
	const deviceGroupsClass = deviceGroupOptions.length + customDeviceGroupOptions.length > maxContentGroupLines ? 'overflowed' : '';

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'roleId' && formData.roleId === -1) {
			return;
		}
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};
	
	const handleRadioChange = (e, group, isCustom = false) => {
		if (isCustom) {
			setFormData(prevData => ({
				...prevData,
				customDeviceGroup: group,
				deviceGroup: null // Ensure only one group type is selected at a time
			}));
		}
		else {
			setFormData(prevData => ({
				...prevData,
				deviceGroup: group,
				customDeviceGroup: null // Ensure only one group type is selected at a time
			}));
		}
	};

	const handleGuruCheckChange = (e, guruItem) => {
		const { checked } = e.target;
		setFormData(prevData => {
			let updatedGurus;
			if (checked) {
				updatedGurus = [...prevData.gurus, guruItem.Id];
			}
			else {
				updatedGurus = prevData.gurus.filter(g => g !== guruItem.Id);
			}
			return { ...prevData, gurus: updatedGurus };
		});
	}

	const handleGrandfatherCheckboxChange = (e, group) => {
		const { checked } = e.target;
		setFormData((prevData) => ({
			...prevData,
			contentGroups: prevData.contentGroups.map((g) =>
				g.Id === group.Id ? { ...g, Grandfather: checked } : g
			)
		}));
	};

	const handleNoDevicesSelected = () => {
		setFormData(prevData => ({
			...prevData,
			deviceGroup: null,
			customDeviceGroup: null
		}));
	};

	const editingMultipleUsers = edit && users.length > 1;

	const SingleUser = (
		<>
			<div>
				<label>
					<h4>First Name</h4>
					<input
						type="text"
						name="firstName"
						maxLength="100"
						value={formData.firstName}
						onChange={handleChange}
						disabled={editingMultipleUsers}
						required={!edit}
						placeholder={editingMultipleUsers ? '-' : 'Joe'}
						autoFocus
					/>
				</label>
			</div>
			<div>
				<label>
					<h4>Last Name</h4>
					<input
						type="text"
						name="lastName"
						maxLength="100"
						value={formData.lastName}
						onChange={handleChange}
						disabled={editingMultipleUsers}
						required={!edit}
						placeholder={editingMultipleUsers ? '-' : 'Bloggs'}
					/>
				</label>
			</div>
			<div>
				<label>
					<h4>Email</h4>
					<input
						type="email"
						name="username"
						maxLength="320"
						value={formData.username}
						onChange={handleChange}
						disabled={edit}
						required={!edit}
						placeholder={editingMultipleUsers ? '-' : 'name@example.com'}
					/>
				</label>
			</div>
		</>
	);

	const MultipleUsers = (
		<div>
			<h4>Selected Users</h4>
			<ul className="delete-user-list">
				{users.map((user) => (
					<li key={user.Id} className="delete-user-list-item">{user.FullName}</li>
				))}
			</ul>
		</div>
	);
	
	const handleGroupCheckboxChange = (e, group, groupType) => {
		const { checked } = e.target;
		setFormData((prevData) => {
			let updatedGroups;
			if (checked) {
				updatedGroups = [...prevData[groupType], { ...group, Grandfather: false }];
			}
			else {
				updatedGroups = prevData[groupType].filter(g => g.Id !== group.Id);
			}
			return { ...prevData, [groupType]: updatedGroups };
		});
	};
	
	const renderUserNomenclature = () => {
		if (users.length < 2) {
			return SingleUser;
		}
		else {
			return MultipleUsers;
		}
	};

	const renderRole = () => {
		if (userData.UserLevel) {
			const availableRoles = prerequisites?.RoleData?.filter((role) => {
				return role.Level >= Number(userData.UserLevel);
			});
			const availableRoleExists = availableRoles.some((role) => {
				return role.Id === Number(formData.roleId);
			});
			const notAvailableRoleName = prerequisites?.RoleData?.filter((role) => {
				return role.Id === Number(formData.roleId);
			});
			return (
				<select
					name="roleId"
					value={formData.roleId}
					onChange={handleChange}
					required
					disabled={formData.roleId === -1}
				>
					{(!availableRoleExists && edit) ? (
						<option value={formData.roleId} disabled>{notAvailableRoleName[0].Name.replace(/([a-z])([A-Z])/g, '$1 $2')}</option>
					) : (formData.roleId === -1) ? (
						<option value={-1} disabled>Mixed</option>
					) : availableRoles.map((role) => (
						<option key={role.Id} value={role.Id}>{role.Name.replace(/([a-z])([A-Z])/g, '$1 $2')}</option>
					))}
				</select>
			);
		}
		return null;
	};

	const renderDeviceGroup = (formData, group, custom) => {
		const userCount = deviceGroupUserCount[group.Id] || 0;
		const checked = (!custom && !formData.customDeviceGroup && formData.deviceGroup?.Id === group.Id) || (formData.customDeviceGroup?.Id === group.Id);
		return (
			<div key={group.Id}>
				<label>
					<p className="contentGroup">
						<input
							type="radio"
							name="deviceGroup"
							checked={checked}
							onChange={(e) => handleRadioChange(e, group, custom)}
						/>
						{group.Name || group.DeviceGroupName} {userCount > 0 && <>({userCount} users)</>}
						<FontAwesomeIcon
							icon={faPenToSquare}
							onClick={() => handleDeviceIconClick(group, custom)}
							className="edit-icon"
							style={{ marginLeft: '10px', cursor: 'pointer' }}
						/>
						{custom &&
							<FontAwesomeIcon
								icon={faXmark}
								onClick={() => handleDeleteIconClick(group)}
							/>
						}
					</p>
				</label>
			</div>
		);
	};

	const renderGuruAccessSection = () => {
		if (packagesWithGurusByUser && packagesWithGurusByUser.length > 0) {
			return (
				<div className="user-management-modal-grid-colOne checkbox-list">
					<h4>Guru Access</h4>
					<div>
						{packagesWithGurusByUser.map((guru) => {
							const isPresentForSome = users.some((user) => (user.GuruLinks || []).includes(guru.Id));
							const isPresentForAll = users.every((user) => (user.GuruLinks || []).includes(guru.Id));
							const isMixed = isPresentForSome && !isPresentForAll;
							const isChecked = formData.gurus.some((g) => g === guru.Id);
							return (
								<div key={guru.Id}>
									<label>
										<p className={`package ${isMixed ? 'mixed' : ''}`}>
											<input
												type="checkbox"
												name={guru.Value}
												checked={isChecked}
												onChange={(e) => handleGuruCheckChange(e, guru)}
											/>
											{guru.Value} Enabled
										</p>
									</label>
								</div>
							);
						})}
					</div>
				</div>
			);
		}
		return null;
	}

	return (
		<form>
			<div className="user-management-modal-grid">
				{renderUserNomenclature()}
				<div>
					<label>
						<h4>Access Level</h4>
						{renderRole()}
					</label>
				</div>
				<div className="user-management-modal-grid-colOneSpanTwo checkbox-list">
					<h4>Content Groups</h4>
					<div className={contentGroupsClass}>
						{contentGroupOptions.map((group) => {
							const isPresentForSome = users.some((user) => (user.ContentGroups || []).map((g) => g.Id).includes(group.Id));
							const isPresentForAll = users.every((user) => (user.ContentGroups || []).map((g) => g.Id).includes(group.Id));
							const isMixed = isPresentForSome && !isPresentForAll;
							const isChecked = formData.contentGroups.some((g) => g.Id === group.Id);
							const matchingGroup = formData.contentGroups.find((g) => g.Id === group.Id);
							const isGrandfatherChecked = matchingGroup ? matchingGroup.Grandfather : false;

							return (
								<div key={group.Id}>
									<label>
										<p className={`checkbox contentGroup ${isMixed ? 'mixed' : ''}`}>
											<input
												type="checkbox"
												name={group.Name}
												checked={isChecked}
												onChange={(e) => handleGroupCheckboxChange(e, group, 'contentGroups')}
											/>
											{group.Name}
										</p>
									</label>
									{group.HasTraversalRule &&
										<div className="unlock-content">
											<label className="checkbox-la">
												<p className="checkbox unlock">
													<input
														type="checkbox"
														name="Locked Learning Journey Content"
														checked={isGrandfatherChecked}
														onChange={(e) => handleGrandfatherCheckboxChange(e, group)}
													/>
													Unlock all Modules <InfoIcon text={"Some modules force the user to complete the content in a set order. Unlocking allows the user to skip the content and take the test at the end. This should only be checked for users who are experienced and do not need to complete the full learning journey."} />
												</p>
											</label>
										</div>
									}
								</div>
							);
						})}
					</div>
				</div>
				{(deviceGroupOptions.length > 0 || customDeviceGroupOptions.length > 0) && (
					<div className="user-management-modal-grid-colTwoSpanTwo checkbox-list">
						<h4>Device Groups</h4>
						<div className={deviceGroupsClass}>
							{deviceGroupOptions.map((group) => {
								return renderDeviceGroup(formData, group, false);
							})}
							{customDeviceGroupOptions.map((group) => {
								return renderDeviceGroup(formData, group, true);
							})}
							<div>
								<label>
									<p className="contentGroup">
										<input
											type="radio"
											name="deviceGroup"
											checked={!formData.deviceGroup && !formData.customDeviceGroup}
											onChange={handleNoDevicesSelected}
										/>
										No Devices
									</p>
								</label>
							</div>
						</div>
					</div>
				)}
				{renderGuruAccessSection()}
			</div>
		</form>
	);
};

export default UserForm;

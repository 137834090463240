import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "./InfoIcon.scss";

const InfoIcon = ({ text }) => {
	return text ? (
		<div className="info-icon">
			<FontAwesomeIcon icon={faCircleInfo} />
			<p className="info-icon-text">{text}</p>
		</div>
	) : null;
};

export default InfoIcon;

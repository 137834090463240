import { faChevronRight, faLockOpen, faPersonChalkboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonType } from "components/Button/Button";
import { DataFetcher } from "components/DataFetcher";
import MediaType from "components/MediaType/MediaType";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModuleStateType, TraversalRuleType } from "utils/constants";
import ProgressIcon from "./ProgressIcon";
import "./SubModuleAccordion.scss";

const SubModuleAccordion = ({
	expanded,
	courseId,
	moduleId,
	submoduleCount,
	guruUrl,
	webcast,
	contactNumber,
	state,
	handleOpenLockModal,
	handleOpenCourseTraversalModal,
	onExpand,
	headerRef
}) => {
	const [toggle, setToggle] = useState(expanded);
	const [module, setModule] = useState({});
	const [submodules, setSubmodules] = useState([]);
	const [height, setHeight] = useState("0");
	const panelRef = useRef(null);
	const buttonRef = useRef(null);
	const navigate = useNavigate();
	const [fetchData, setFetchData] = useState({});

	useEffect(() => {
		setToggle(expanded);
	}, [expanded]);

	const togglePanel = () => {
		onExpand(); // Trigger the expansion change
	};

	// Scroll to the accordion only after data is fetched and accordion is expanded
	useEffect(() => {
		if (panelRef.current && toggle) {
			if (submodules.length === 0) {
				setFetchData({ url: "/api/Submodules/ByModuleId/" + moduleId });
			}
			else {
				setHeight(`${panelRef.current.scrollHeight}px`);
				// Scroll the header into view
				if (headerRef?.current) {
					const topPosition = headerRef.current.getBoundingClientRect().top + window.scrollY - 30;
					window.scrollTo({ top: topPosition, behavior: "smooth" });
				}
			}
		}
		else {
			setHeight("0");
		}
	}, [toggle, submodules, moduleId]);

	const handleSetData = useCallback((data) => {
		if (submodules.length === 0) {
			setSubmodules((prev) => {
				data.SubContent.sort((a, b) => {
					return a.Position - b.Position;
				});
				return data.SubContent;
			});
			setModule(data);
			if (toggle && panelRef.current) {
				setHeight(`${panelRef.current.scrollHeight}px`);
				// Scroll the button into view after setting the data
				buttonRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Adding "submodules" and "toggle" causes additional (unnecessary) requests by the DataFetcher component

	const handleClick = (submodule) => {
		navigate(`/player#courseId-${courseId}-moduleId-${moduleId}-submoduleId-${submodule.Id}`);
	};

	const getExtraGuruText = () => {
		let message = !webcast
			? "Need expert help with a part of your phone system? Book a free 1 to 1 guru session with us."
			: `Need expert help with this topic? Book a free 1 to 1 Guru session or call us on ${contactNumber} to schedule a webcast.`;
		return (
			<div className="extra-text">
				<h3>Still searching for what you need?</h3>
				<p>{message}</p>
			</div>
		);
	};

	if (submoduleCount === 0) {
		return null;
	}

	return (
		<div className="list-accordion">
			<DataFetcher fetchData={fetchData} setData={handleSetData}>
				<button ref={buttonRef} className="accordionToggle" onClick={togglePanel}>
					<span className="button-text">{toggle ? "Hide" : "See"} {submoduleCount} Submodules</span>
					<span className="button-arrow">
						<span className={`button-arrow-icon ${toggle ? "toggled" : ""}`}>
							<FontAwesomeIcon icon={faChevronRight} />
						</span>
					</span>
				</button>
				<div
					ref={panelRef}
					className="list-accordion-panel"
					style={{ height: height, transition: "height 0.8s ease" }}
				>
					<h3>Submodules</h3>
					{submodules.map((submodule, index) => (
						<div className="submodule" key={index}>
							<ProgressIcon
								state={submodule.State !== ModuleStateType.CourseTraversal ? state : submodule.State}
								ruleType={module.TraversalRule}
								hasContent={submodule.ContentType.length}
								className="submodule-progress"
								hasProgress={submodule.HasProgress}
								progress={submodule.Progress}
								handleClick={() => handleClick(submodule)}
								handleOpenLockModal={handleOpenLockModal}
								handleOpenCourseTraversalModal={handleOpenCourseTraversalModal}
							/>
							<div className="submodule-details">
								<div className="submodule-details-text">
									<h4>{submodule.Name}</h4>
									<MediaType type={submodule.ContentType} />
								</div>
								{submodule.State === ModuleStateType.CourseTraversal && <div className="submodule-details-llj">
									{module.TraversalRule === TraversalRuleType.EnableAfterPrevious ?
										<p>Complete the previous submodule to unlock this submodule</p> :
										<p>Complete the first submodule to unlock this submodule</p>
									}
								</div>}
							</div>
						</div>
					))}
					{guruUrl.length > 0 && (
						<div className="extra">
							<div className="extra-icon">
								<FontAwesomeIcon icon={faPersonChalkboard} className="fa-2x" />
							</div>
							{getExtraGuruText()}
							<div className="extra-link">
								<Button type={ButtonType.primary} text="Book Now" to={guruUrl} newTab />
							</div>
						</div>
					)}
					{state === ModuleStateType.DisabledUpsell && (
						<div className="extra">
							<div className="extra-icon">
								<FontAwesomeIcon icon={faLockOpen} className="fa-2x" />
							</div>
							<div className="extra-text">
								<h3 className="extra-text-alone">Want to Unlock this Content?</h3>
							</div>
							<div className="extra-link">
								<Button type={ButtonType.Secondary} onClick={handleOpenLockModal} text="Unlock Now" />
							</div>
						</div>
					)}
				</div>
			</DataFetcher>
		</div>
	);
};

export default SubModuleAccordion;
